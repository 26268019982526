import React, { useCallback } from "react";

import { t } from "i18next";
import { Controller, useForm } from "react-hook-form";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { toast } from "react-toastify";
import { useDebounce } from "usehooks-ts";

import queryClient from "@app/queryClient";
import Button from "@components/data-entry/Button";
import Select from "@components/data-entry/Select";
import TextField from "@components/data-entry/TextField";
import CalloutBox from "@components/feedback/CalloutBox";
import Loading from "@components/feedback/Loading";
import BottomBar from "@components/layout/BottomBar";
import {
  AccountStatusList,
  AccountTypeList,
  OrganizationAccount,
} from "@models/OrganizationAccount";
import { getFullAccountsEndpoint } from "@services/api/accounts/get-full-accounts";
import { getAPIQueryKey } from "@services/api/helper";
import { fetchAccountsSearchByName } from "@services/api/old/accounts/fetch-accounts-search-by-name";
import { useUpsertAccount } from "@services/api/old/accounts/upsert-account";
import { useFetchCollections } from "@services/api/old/collections/fetch-collections";
import {
  GetPortfoliosResponseItem,
  useGetPortfolios,
} from "@services/api/portfolios/get-portfolios";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import countries, { countriesOptions } from "@services/countries";
import LogService from "@services/log/service";
import { useUserRole } from "@shared/components/access-control/helpers";
import { useBrands } from "@shared/hooks/useBrands";
import useUserRepresentativeId from "@shared/hooks/useUserRepresentativeId";

import { AccountUpsertData, AccountWithCollections } from "./type";

type FormValues = {
  name: string;
  city: string;
  countryCode: string;
  type: OrganizationAccount["type"];
  collectionsStatus: Record<string, OrganizationAccount["status"]>;
  portfolios: Record<string, string>;
};

interface UpsertAccountFormProps {
  selectedAccount?: AccountWithCollections;
  onSuccess: () => void;
  onError: (error: string) => void;
  onCancel: () => void;
}

export default function UpsertAccountForm({
  selectedAccount,
  onSuccess,
  onError,
  onCancel,
}: UpsertAccountFormProps) {
  const { getPortfolioLabel } = useBrands();
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  const userRepresentativeId = useUserRepresentativeId();
  const { isAgent } = useUserRole();

  // function used to filter available options for agents when editing/creating accounts
  const filterAgentPortfolios = useCallback(
    (p1: GetPortfoliosResponseItem) =>
      isAgent ? p1.manager && p1.manager.id === userRepresentativeId : true,
    [isAgent, userRepresentativeId],
  );

  const { data: collections, isLoading: isLoadingCollections } =
    useFetchCollections(organizationId);

  const {
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
    control,
  } = useForm<FormValues>({
    mode: "onTouched",
    defaultValues: {
      name: selectedAccount?.name,
      city: selectedAccount?.city,
      countryCode: selectedAccount?.countryCode,
      type: selectedAccount?.type,
      collectionsStatus:
        selectedAccount?.collections?.reduce(
          (acc, collection) => ({
            ...acc,
            [collection.id!]: collection.status,
          }),
          {},
        ) ||
        collections?.reduce(
          (acc, collection) => ({
            ...acc,
            [collection.id!]: "PROSPECT",
          }),
          {},
        ) ||
        {}, // Fallback to empty object if no collections exist
      portfolios: {
        wholeshowroom:
          selectedAccount?.portfolios && selectedAccount?.portfolios.length > 0
            ? selectedAccount?.portfolios[0].id
            : "",
      }, // Fallback to empty object if no portfolios exist
    },
  });

  const { data: portfolios, isLoading: isLoadingPortfolios } = useGetPortfolios(
    {
      organizationId,
    },
  );

  const accountName = watch("name");
  const debouncedAccountName = useDebounce(accountName, 300);

  const { mutateAsync: upsertAccount, isPending } = useUpsertAccount(
    organizationId,
    selectedAccount?.id,
  );

  const onSubmitForm = (data: FormValues) => {
    const continentCode = countries[data.countryCode]?.continentCode;
    const accountData: AccountUpsertData = {
      id: selectedAccount?.id,
      name: data.name,
      city: data.city,
      countryCode: data.countryCode,
      continentCode: continentCode || "",
      status: "ACTIVE", // TODO: REMOVE IT
      type: data.type,
      collections: Object.keys(data.collectionsStatus).map((collectionId) => ({
        id: collectionId,
        status: data.collectionsStatus[collectionId],
      })),
      portfolios: Object.keys(data.portfolios).map((portfolioId) => ({
        id: data.portfolios[portfolioId],
      })),
    };
    upsertAccount(accountData)
      .then(async () => {
        await queryClient.invalidateQueries({
          queryKey: getAPIQueryKey(
            getFullAccountsEndpoint.path(organizationId),
          ),
        });
        reset();
        onSuccess();
        if (selectedAccount?.id) {
          toast.success(t("CRM.accounts.form.account-updated"));
        } else {
          toast.success(t("CRM.accounts.form.account-created"));
        }
      })
      .catch((err) => {
        LogService.error(err);
        onError(err);
      });
  };

  const verifyAccountName = async (name: string) => {
    let accounts = null;

    if (
      (selectedAccount && debouncedAccountName !== selectedAccount.name) ||
      (!selectedAccount && name === debouncedAccountName)
    ) {
      accounts = await fetchAccountsSearchByName(
        organizationId,
        debouncedAccountName,
      );
    }

    if (accounts && accounts.length > 0) {
      return accounts.some((account) => {
        if (selectedAccount) {
          return account.name === name && account.id !== selectedAccount.id;
        }
        return account.name === name;
      });
    }

    return false;
  };

  if (isLoadingCollections || isLoadingPortfolios) {
    return <Loading type="screen" />;
  }

  return (
    <form
      className="flex flex-col h-full"
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <div className="px-4 py-6 lg:p-10 flex flex-col gap-10 grow">
        <div className="flex flex-col gap-2">
          <p className="text-base">
            {t("CRM.accounts.form.global-information")}
          </p>
          <hr />
        </div>
        <div className="flex flex-col gap-6">
          <div className="w-full lg:w-2/3 xl:w-1/2">
            <label className="font-medium" htmlFor="name">
              {t("CRM.accounts.form.name-label")}
            </label>
            <div className="mt-2">
              <Controller
                name="name"
                control={control}
                rules={{
                  required: true,
                  validate: {
                    exists: async (value) => {
                      const exists = await verifyAccountName(value);
                      return !exists;
                    },
                  },
                }}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <TextField
                    id="name"
                    placeholder={t("CRM.accounts.form.name-placeholder")}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    aria-invalid={errors.name ? "true" : "false"}
                    hasError={!!error}
                  />
                )}
              />
              {errors.name && errors.name.type === "required" && (
                <p className="text-sm italic text-primaryRed">
                  {t("Common.form.this-is-required")}
                </p>
              )}
              {errors.name && errors.name.type === "exists" && (
                <p className="text-sm italic text-primaryRed">
                  {t("CRM.accounts.form.name-error-already-exists")}
                </p>
              )}
            </div>
          </div>
          <div className="w-full lg:w-2/3 xl:w-1/2">
            <label className="font-medium" htmlFor="city">
              {t("CRM.accounts.form.city-label")}
            </label>
            <div className="mt-2">
              <Controller
                name="city"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <TextField
                    id="city"
                    placeholder={t("CRM.accounts.form.city-placeholder")}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    hasError={!!error}
                  />
                )}
              />
              {errors.city && errors.city.type === "required" && (
                <p className="text-sm italic text-primaryRed">
                  {t("Common.form.this-is-required")}
                </p>
              )}
            </div>
          </div>
          <div className="w-full lg:w-2/3 xl:w-1/2">
            <label className="font-medium" htmlFor="countryCode">
              {t("CRM.accounts.form.country-select")}
            </label>
            <div className="mt-2">
              <Controller
                name="countryCode"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <Select
                    autocomplete
                    id="countryCode"
                    name="countryCode"
                    placeholder={t(
                      "CRM.accounts.form.country-select-placeholder",
                    )}
                    placeholderInput={t(
                      "CRM.accounts.form.country-autocomplete-placeholder",
                    )}
                    className={error && "border-primaryRed"}
                    defaultValue={{
                      value,
                    }}
                    onChange={onChange}
                    onBlur={onBlur}
                    options={[
                      {
                        key: "countryCode_grp1",
                        label: "COUNTRIES",
                        options: countriesOptions.map((country) => ({
                          label: `${country.flag} ${country.label}`,
                          value: country.value,
                        })),
                      },
                    ]}
                  />
                )}
              />
              {errors.countryCode && errors.countryCode.type === "required" && (
                <p className="text-sm italic text-primaryRed">
                  {t("Common.form.this-is-required")}
                </p>
              )}
            </div>
          </div>
          <div className="w-full lg:w-2/3 xl:w-1/2">
            <label className="font-medium" htmlFor="type">
              {t("CRM.accounts.form.type-select")}
            </label>
            <div className="mt-2">
              <Controller
                name="type"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error },
                }) => (
                  <Select
                    id="type"
                    name="type"
                    placeholder={t("CRM.accounts.form.type-select-placeholder")}
                    className={error && "border-primaryRed"}
                    defaultValue={{
                      value,
                    }}
                    onChange={onChange}
                    onBlur={onBlur}
                    options={[
                      {
                        key: "type_grp1",
                        label: "TYPES",
                        options: AccountTypeList.map((type) => ({
                          label: t(`Common.account-type.${type}`),
                          value: type,
                        })),
                      },
                    ]}
                  />
                )}
              />
              {errors.type && errors.type.type === "required" && (
                <p className="text-sm italic text-primaryRed">
                  {t("Common.form.this-is-required")}
                </p>
              )}
            </div>
          </div>
        </div>
        {collections && collections.length > 0 && (
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <p className="text-base">
                {t("CRM.accounts.form.collections-status")}
              </p>
              <hr />
            </div>
            <div className="flex flex-col gap-6 lg:flex-wrap lg:flex-row">
              {collections.map((collection) => (
                <div
                  key={`collection-status-${collection.id}`}
                  className="w-full lg:w-max lg:min-w-max"
                >
                  <label
                    className="font-medium"
                    htmlFor={`collectionsStatus.${collection.id}`}
                  >
                    {t("CRM.accounts.form.collection-status-label", {
                      name: collection.name,
                    })}
                  </label>
                  <div className="w-full mt-2">
                    <Controller
                      name={`collectionsStatus.${collection.id}`}
                      control={control}
                      rules={{ required: true }}
                      render={({
                        field: { onChange, value, onBlur },
                        fieldState: { error },
                      }) => (
                        <Select
                          name={`collectionsStatus.${collection.id}`}
                          placeholder="Select a status"
                          className={error && "border-primaryRed"}
                          onChange={onChange}
                          defaultValue={{
                            value,
                          }}
                          onBlur={onBlur}
                          options={[
                            {
                              key: "status_grp1",
                              label: "STATUS",
                              options: AccountStatusList.map((status) => ({
                                label: t(`Common.account-status.${status}`),
                                value: status,
                              })).sort((a, b) =>
                                a.label.localeCompare(b.label),
                              ),
                            },
                          ]}
                        />
                      )}
                    />
                    {errors.collectionsStatus &&
                      errors.collectionsStatus[collection.id] &&
                      errors.collectionsStatus[collection.id]?.type ===
                        "required" && (
                        <p className="text-sm italic text-primaryRed">
                          {t("Common.form.this-is-required")}
                        </p>
                      )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {portfolios && portfolios.length > 0 && (
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <p className="text-base">
                {t("CRM.accounts.form.portfolios-associated")}
              </p>
              <hr />
            </div>
            <div className="flex flex-col gap-6 lg:flex-wrap lg:flex-row">
              <div className="w-full lg:w-max lg:min-w-max">
                <label
                  className="font-medium"
                  htmlFor="portfolios.wholeshowroom"
                >
                  {t("CRM.accounts.form.whole-showroom-portfolio-label")}
                </label>
                <div className="w-full lg:max-w-lg mt-2">
                  <Controller
                    name="portfolios.wholeshowroom"
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, value, onBlur },
                      fieldState: { error },
                    }) => (
                      <Select
                        name="portfolios.wholeshowroom"
                        placeholder="Select a portfolio"
                        className={error && "border-primaryRed"}
                        onChange={onChange}
                        width="FIT"
                        defaultValue={{
                          value,
                        }}
                        onBlur={onBlur}
                        options={[
                          {
                            key: "portfolio_grp1",
                            label: "PORTFOLIOS",
                            options: portfolios
                              .filter(
                                (porfolio) => porfolio.collectionId === null,
                              )
                              .filter(filterAgentPortfolios)
                              .sort((a, b) => a.name.localeCompare(b.name))
                              .map((portfolio) => ({
                                label: getPortfolioLabel(portfolio),
                                value: portfolio.id,
                              })),
                          },
                        ]}
                      />
                    )}
                  />
                  {errors.portfolios &&
                    errors.portfolios.wholeshowroom &&
                    errors.portfolios.wholeshowroom?.type === "required" && (
                      <p className="text-sm italic text-primaryRed">
                        {t("Common.form.this-is-required")}
                      </p>
                    )}
                </div>
              </div>
            </div>
          </div>
        )}
        <CalloutBox type="INFORMATIVE">
          {t("CRM.accounts.form.portfolio-callout")}
        </CalloutBox>
      </div>
      <BottomBar theme="WHITE">
        <Button type="button" onClick={onCancel}>
          {selectedAccount?.id
            ? t("CRM.accounts.buttons.cancel-update")
            : t("Components.buttons.cancel")}
        </Button>
        <Button theme="PRIMARY" disabled={!isValid || isPending}>
          {selectedAccount?.id
            ? t("CRM.accounts.buttons.update")
            : t("CRM.accounts.buttons.create")}
          <HiOutlineCheckCircle className="w-5 h-5" />
        </Button>
      </BottomBar>
    </form>
  );
}
