import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { brandSchema } from "@models/Brand";
import { dateHydratingSchema } from "@models/types/Dates";
import { ShowroomSeasonList } from "@models/types/enums";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey, handleParsingError } from "@services/api/helper";

// moved to the top to avoid the "Move function declaration to program root no-inner-declarations" error
function isEnabled(
  params: GetOrderDeadline.HookParams,
): params is Required<GetOrderDeadline.HookParams> {
  return !!params.brandId && !!params.season && !!params.year;
}

export namespace GetOrderDeadline {
  export const outputSchema = z.object({
    brandId: brandSchema.shape.id,
    deadline: dateHydratingSchema,
    season: z.enum(ShowroomSeasonList),
    year: z.coerce.number(),
  });

  export type Output = z.infer<typeof outputSchema>;

  export interface HookParams {
    brandId: string | undefined;
    season: string | undefined;
    year: number | undefined;
  }

  export function path({ brandId, season, year }: Required<HookParams>) {
    return `/brands/${brandId}/order-deadlines/${season}/${year}`;
  }

  export const getQueryKeys = (p: Required<HookParams>) =>
    getAPIQueryKey(path(p));

  export function call(params: Required<HookParams>) {
    return axiosInstance
      .get<Output>(path(params))
      .then((e) =>
        handleParsingError(
          outputSchema,
          e.data,
          "Error parsing order deadline",
        ),
      );
  }

  export function useHook(params: HookParams) {
    return useQuery({
      queryKey: getQueryKeys(params),
      queryFn: () => call(params),
      enabled: isEnabled(params),
    });
  }
}
